import React, { useState, useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

//Images
import invision from "../../src/assets/img/tech-icons/invision.svg"
import figma from "../../src/assets/img/tech-icons/figma.svg"
import icon1 from "../assets/img/oca-icons/sharing.svg"
import icon2 from "../assets/img/oca-icons/idea.svg"
import icon3 from "../assets/img/oca-icons/choose.svg"
import icon4 from "../assets/img/oca-icons/prototype.svg"
import icon5 from "../assets/img/oca-icons/tested.svg"

// Styles
import styles from "../sass/pages/portfolio.module.scss"

let lang = ""

const Oca = () => {
  lang = useIntl().locale

  const data = useStaticQuery(graphql`
    query ImagesOca {
      oca: file(relativePath: { eq: "ocaMockup.png" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ocaPictureOne: file(relativePath: { eq: "ocaPictureOne.jpg" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ocaPictureTwo: file(relativePath: { eq: "ocaPictureTwo.jpg" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ocaPictureThree: file(relativePath: { eq: "ocaPictureThree.jpg" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ocaPictureFour: file(relativePath: { eq: "ocaPictureFour.jpg" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ocaMockupTwo: file(relativePath: { eq: "ocaMockupTwo.png" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ocaFirstPicture: file(relativePath: { eq: "ocaFirstPicture.jpg" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ocaSecondPicture: file(relativePath: { eq: "ocaSecondPicture.jpg" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ocaThirdPicture: file(relativePath: { eq: "ocaThirdPicture.jpg" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

    }
  `)

  const title = useIntl().formatMessage({ id: "portfolio" })
  const [load] = useState(0)

  // const verticalBand = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    // verticalBand.current.style.height = document.body.offsetHeight + "px";
  }, [load])

  return (
    <Layout color="black">
      <SEO title={title} />
      {/* <div ref={ verticalBand } className={ styles.verticalBand }></div> */}

      <article> 
      <section >
        <div className={ "container-large " + styles.topCase + " " + styles.topCaseoca}>
          <div className={"grid-cards-2"}>
            <div className={ styles.topTitle }>
                <h1 className="h3"><FormattedMessage id="case_oca_title"/></h1>
            </div> 
            <div>
                <p className="p-small"><FormattedMessage id="case_oca_paragraph_1" values={{breakLine:<br/>}}/></p>
            </div>
          </div>
        </div>
        
      </section>
    </article>

    <article>
        <section className={"container-large " + styles.center}>
          <div className="container-small">
            
          <div className={ styles.picture + " " + styles.space + " " + styles.ocaTopPicture}>
                  <figure>
                   <Img fixed={ data.oca.childImageSharp.fixed } alt="Oca App Mockup" />
                  </figure>
                </div>

              <div className={"container "}>
                <h3><FormattedMessage id="case_oca_title_2"/></h3>
                <p><FormattedMessage id="case_oca_paragraph_2" values={{breakLine:<br/>}}/></p>
                </div>
          </div>

                
                <div className={"grid-cards-2 " + styles.gridPictures}>
                <div className={ styles.picture }>
                  <figure>
                   <Img fluid={ data.ocaPictureOne.childImageSharp.fluid } alt="Oca Prototype Picture" />
                  </figure>
                </div>

                <div className={ styles.picture }>
                  <figure>
                   <Img fluid={ data.ocaPictureTwo.childImageSharp.fluid } alt="Oca Prototype Picture" />
                  </figure>
                </div>

                <div className={ styles.picture }>
                  <figure>
                   <Img fluid={ data.ocaPictureThree.childImageSharp.fluid } alt="Oca Prototype Picture" />
                  </figure>
                </div>

                <div className={ styles.picture }>
                  <figure>
                   <Img fluid={ data.ocaPictureFour.childImageSharp.fluid } alt="Oca Prototype Picture" />
                  </figure>
                </div>
                </div>

                <div className={"container-small " + styles.center}>
                <p><FormattedMessage id="case_oca_paragraph_3" values={{breakLine:<br/>}}/></p>
                <div className={"grid-cards-3 " + styles.gridPictures}>
                <div className={ styles.picture }>
                  <figure>
                   <Img fluid={ data.ocaSecondPicture.childImageSharp.fluid } alt="Oca Picture" />
                  </figure>
                  </div>
                  <div className={ styles.picture }>
                  <figure>
                   <Img fluid={ data.ocaFirstPicture.childImageSharp.fluid } alt="Oca Picture" />
                  </figure>
                  </div>
                  <div className={ styles.picture }>
                  <figure>
                   <Img fluid={ data.ocaThirdPicture.childImageSharp.fluid } alt="Oca Picture" />
                  </figure>
                  </div>
                </div>
                <p><FormattedMessage id="case_oca_paragraph_4" values={{breakLine:<br/>}}/></p>
                <p><FormattedMessage id="case_oca_paragraph_5" values={{breakLine:<br/>}}/></p>
                </div>
                </section>
                </article>

              <div className={styles.space}>
              <div className={"container-large "  +styles.blueBackground}>
                <h3 className={styles.center + " " + styles.space}>Design Sprint</h3>
              
              <div className={"grid-cards-5 "}>
                
                <div className="blueCardTwo">
                  <div >
                    <img src={icon1} alt="" className={styles.icon}/>
                  </div>
                  <h4><FormattedMessage id="case_rp_card_title_1"/></h4>
                  <p><FormattedMessage id="case_oca_sprint_paragraph_1"/></p>
                </div>

                <div className="blueCardTwo">
                  <div >
                    <img src={icon2} alt="" className={styles.icon}/>
                  </div>
                  <h4><FormattedMessage id="case_rp_card_title_2"/></h4>
                  <p><FormattedMessage id="case_oca_sprint_paragraph_2"/></p>
                </div>

                <div className="blueCardTwo">
                  <div >
                    <img src={icon3} alt="" className={styles.icon}/>
                  </div>
                  <h4><FormattedMessage id="case_rp_card_title_3"/></h4>
                  <p><FormattedMessage id="case_oca_sprint_paragraph_3"/></p>
                </div>

                <div className="blueCardTwo">
                  <div >
                    <img src={icon4} alt="" className={styles.icon}/>
                  </div>
                  <h4><FormattedMessage id="case_rp_card_title_4"/></h4>
                  <p><FormattedMessage id="case_oca_sprint_paragraph_4"/></p>
                </div>

                <div className="blueCardTwo">
                  <div >
                    <img src={icon5} alt="" className={styles.icon}/>
                  </div>
                  <h4><FormattedMessage id="case_rp_card_title_5"/></h4>
                  <p><FormattedMessage id="case_oca_sprint_paragraph_5"/></p>
                </div>

              </div>
              </div>
              </div>


      <article>
        <section>
          <div className={"container-large "}>
            <div className={styles.space  + " " + styles.space}>
              <div className={"container-small " + styles.center}>
                <h3><FormattedMessage id="case_movie_title_5"/></h3>
                <p><FormattedMessage id="case_oca_paragraph_7" values={{breakLine:<br/>}}/></p>
                <div className={"grid-cards-2 " + styles.space + " " + styles.centeredCards}>
     
                  <div className="whiteCardSquare">
                    <div className="whiteCardIconMedium">
                      <img src={figma} alt="Figma" />
                    </div>
                   <p><FormattedMessage id="figma"/></p>
                  </div>

                  <div className="whiteCardSquare">
                    <div className="whiteCardIconMedium">
                      <img src={invision} alt="Invision" />
                    </div>
                    <p><FormattedMessage id="invision"/></p>
                  </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </article>

        <article>
      <div className={styles.topLineCaseOca}></div>
        <div className={"container-large " + styles.result + " " + styles.ocaResult + " " + styles.space}>
          <div className={"container-large grid-cards-2-1 " + styles.result + " " + styles.bottomContainer}>
            <div><h3><FormattedMessage id="case_movie_title_6" values={{breakLine:<br/>}}/></h3><br/><p><FormattedMessage id="case_oca_paragraph_6" values={{breakLine:<br/>}}/></p>

              </div>
                <div className={ styles.picture + " " + styles.bottomContainerDiv}>
                <figure>
                   <Img fluid={ data.ocaMockupTwo.childImageSharp.fluid } alt="Oca Prototype Picture" />
                </figure>
              </div>
            </div>

      </div>
      <div className={styles.space}></div>
    </article>  

    <article>  
      <div className={"bottomCards " + styles.ocaResult }>
      <div className={"container-large container-wide-mobile " + styles.result}>
      <div className="container">
      <h3><FormattedMessage id="bottom_cards_title"/></h3>
            <div className={"grid-cards-2-1 "}>
            <Link to={`/${lang}` + "/movie"}>
            <div className={'portfolioCard bottomCard ' + styles.movie}>
              <div className="cardContent">
                  <p className="cardHashtags"></p>
                  <p className="cardTitle"><FormattedMessage id="second_project_title" /></p>
              </div>
            </div>
          </Link>
            <Link to={`/${lang}` + "/voy-de-viaje"}>
            <div className={'portfolioCard bottomCard ' + styles.voyDeViaje}>
              <div className="cardContent">
                  <p className="cardHashtags"></p>
                  <p className="cardTitle"><FormattedMessage id="first_project_title"/></p>
              </div>
            </div>
          </Link>
          
            </div>
        </div>
        </div>
        </div>  
    </article> 
     
    </Layout>
  )
}

export default Oca
